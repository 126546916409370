@font-face {
  font-family: 'OpenSans';
  src: url('fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-light';
  src: url('fonts/Roboto-Light.ttf') format('truetype');
}